import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const Title = () => <span>
    Контакты <br />
    Индустратора
  </span>;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const InlineNotification = makeShortcode("InlineNotification");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const MiniCard = makeShortcode("MiniCard");
const layoutProps = {
  Title,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    {/* Page title left out so it can be generated */}
    <PageDescription mdxType="PageDescription">
      <p>{`На странице можете найти нашу контактную информацию.`}</p>
    </PageDescription>
    <InlineNotification mdxType="InlineNotification">
      <p><strong parentName="p">{`Дорогие клиенты:`}</strong>{` Для того, чтобы …`}</p>
    </InlineNotification>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Как с нами связаться?</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Где мы находимся?</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Юридическая информация</AnchorLink>
    </AnchorLinks>
    <h2>{`Как с нами связаться?`}</h2>
    <p><strong parentName="p">{`Телефон`}</strong>{`: `}<inlineCode parentName="p">{`8 (800) 000-000`}</inlineCode></p>
    <p><strong parentName="p">{`E-mail`}</strong>{`: `}<inlineCode parentName="p">{`info@industrator.ru`}</inlineCode></p>
    <h2>{`Где мы находимся?`}</h2>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <p><strong parentName="p">{`Адрес`}</strong>{`: `}<inlineCode parentName="p">{`Мир`}</inlineCode></p>
      </Column>
      <Column colLg={4} mdxType="Column">
        <Row mdxType="Row">
          <MiniCard title="Yandex Карты" href="https://yandex.ru/maps/" className="MiniCard-module--card--dark" mdxType="MiniCard" />
          <MiniCard title="Google Карты" href="https://goo.gl/maps" className="MiniCard-module--card--dark" mdxType="MiniCard" />
        </Row>
      </Column>
    </Row>
    <h2>{`Юридическая информация`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      